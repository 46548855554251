import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "F:/reactKwik/src/layouts/docs.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Link = makeShortcode("Link");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`The Kwikswap Ecosystem is fundamentally contained three sorts of users: liquidity providers, traders, and developers. Liquidity providers are Incentivized to contribute `}<a parentName="p" {...{
        "href": "https://eips.ethereum.org/EIPS/eip-20"
      }}>{`ERC-20`}</a>{` tokens to common liquidity pools. Traders can trade these tokens for each other for a fixed `}<Link to="/docs/v1/advanced-topics/fees" mdxType="Link">{`0.30% fee`}</Link>{` (which goes to liquidity providers and Stake Holders). Developers can incorporate straightforwardly with Kwikswap keen agreements to control new and energizing connections with tokens, exchanging interfaces, retail encounters, and that’s only the tip of the iceberg. `}</p>
    <p>{`Altogether, Interaction between these classes make a positive feedback loop, fueling advanced economies by characterizing a common language through which tokens can be pooled, exchanged, and utilized.`}</p>
    <h1 {...{
      "id": "developersprojects",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#developersprojects",
        "aria-label": "developersprojects permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Developers/Projects`}</h1>
    <p>{`There are far too many ways Kwikswap is used in the wider Ethereum ecosystem to count, but some examples include:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`The open-source, accessible nature of Kwikswap means there are countless UX experiments and front-ends built to offer access to Kwikswap functionality. You can find Kwikswap functions in most of the major DeFi dashboard projects.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Wallets often integrate swapping and liquidity provision functionality as a core offering of their product.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`DEX (decentralized exchange) aggregators pull liquidity from many liquidity protocols to offer traders the best prices but splitting their trades. Kwikswap is the biggest single decentralized liquidity source for these projects.`}</p>
      </li>
    </ul>
    <h1 {...{
      "id": "liquidity-providers",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#liquidity-providers",
        "aria-label": "liquidity providers permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Liquidity Providers`}</h1>
    <p>{`Liquidity providers, or LPs, are not a homogenous group:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Passive LPs are token holders who wish to passively invest their assets to accumulate trading fees.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Professional LPs are focused on market making as their primary strategy. They usually develop custom tools and ways of tracking their liquidity positions across different DeFi projects.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Token projects sometimes choose to become LPs to create a liquid marketplace for their token. This allows tokens to be bought and sold more easily, and unlocks interoperability with other DeFi projects through Kwikswap.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Finally, some DeFi pioneers are exploring complex liquidity provision interactions like incentivized liquidity, liquidity as collateral, and other experimental strategies. Kwikswap is the perfect protocol for projects to experiment with these kinds of ideas.`}</p>
      </li>
    </ul>
    <h1 {...{
      "id": "traders",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#traders",
        "aria-label": "traders permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Traders`}</h1>
    <p>{`There are a several categories of traders in the protocol ecosystem:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Speculators use a variety of community built tools and products to swap tokens using liquidity pulled from the Kwikswap protocol.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Arbitrage bots seek profits by comparing prices across different platforms to find an edge. (Though it might seem extractive, these bots actually help equalize prices across broader Ethereum markets and keep things fair.)`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`DAPP users buy tokens on Kwikswap for use in other applications on Ethereum.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Smart contracts that execute trades on the protocol by implementing swap functionality (from products like DEX aggregators to custom Solidity scripts).`}</p>
      </li>
    </ul>
    <p>{`In all cases, trades are subject to the same flat fee for trading on the protocol. Each is important for increasing the accuracy of prices and incentivizing liquidity.`}</p>
    <h1 {...{
      "id": "kwikswap-team-and-community",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#kwikswap-team-and-community",
        "aria-label": "kwikswap team and community permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Kwikswap Team and Community`}</h1>
    <p>{`The Kwikswap team along with the broader Kwikswap community drives development of the protocol and ecosystem.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      